@import '../../styles/propertySets.css';

.root {
  /* Preserve newlines, but collapse other whitespace */
  white-space: pre-line;
  margin: 18px 0 0 0;
  transition: all 1s ease-in;

  @media (--viewportMedium) {
    margin: 0;
  }
}

.showMore {
  margin: 0 0 0 5px;
}
