@import '../../styles/propertySets.css';

.root {
  margin-top: 24px;
}

.field {
  margin-bottom: 24px;
  margin-top: 24px;

  @media (--viewportMedium) {
    margin-top: 32px;
    margin-bottom: 32px;
  }
}

.coverageText {
  margin-bottom: 24px;
}

.error {
  color: var(--failColor);
  margin-top: 0;

  @media (--viewportMedium) {
    margin-top: 0;
  }
}