.root {
    display: flex;
    align-items: center;
  }
  
  .iconWrapper {
    display: flex;
  
    &:hover {
      cursor: help;
    }
  }
  
  .verifiedIcon {
    height: 32px;
    width: 32px;
  }
  
  .tooltip {
    /* Overwrite tooltip styles */
  
    /* Font */
    line-height: 2;
    color: var(--matterColor) !important;
  
    /* Style */
    background: var(--matterColorLight) !important;
    border: 1px solid transparent !important;
    box-shadow: 0 1px 6px rgb(57 73 76 / 35%);
    padding: 12px 18px !important;
    opacity: 0.95 !important;
  
    max-width: 250px;
  
    &:before,
    &:after {
      display: none;
    }
  }
  
  .text {
    /* Font */
    color: var(--matterColor);
  
    margin-left: 8px;
  }