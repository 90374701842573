@import '../../styles/propertySets.css';
@import '../../styles/customMediaQueries.css';

.root {
  padding-top: 70px;

  @media (--viewportMedium) {
    flex-basis: 567px;
  }
}

.modalContent {
  flex-grow: 1;
}


.coverageText {
  margin-bottom: 24px;
}

/* Icon of the modal */
.modalIcon {
  /* Layout */
  height: 148px;
  width: 148px;

  /* Style */
  fill: none;
  stroke: var(--marketplaceColor);
}
