@import '../../styles/propertySets.css';

.root {
  composes: marketplaceModalBaseStyles from global;

  padding: 70px 0 24px;

  @media (--viewportMedium) {
    flex-basis: 567px;
    padding: var(--modalPaddingMedium);
  }
}

.modalContent {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

/* Icon of the modal */
.modalIcon {
  composes: marketplaceModalIconStyles from global;
  margin-bottom: 23px;
}

/* Title of the modal */
.modalTitle {
  composes: marketplaceModalTitleStyles from global;
  margin-top: 24px;
}

/* Paragraph for the Modal */
.modalMessage {
  composes: marketplaceModalParagraphStyles from global;
}

.modalWrapper {
  margin-bottom: 48px;
}

.modalError {
  margin-top: 0;
  margin-bottom: 12px;

  color: var(--failColor);
}
